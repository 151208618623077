import React, { useState } from "react";
import Navbar from "../components/Navbar";
import "../styles/contact.css"; // Assurez-vous que le chemin du fichier CSS est correct

const apiUrl = process.env.REACT_APP_API_URL;

const Contact = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { email, subject, text };

    try {
      const response = await fetch(`${apiUrl}/api.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        setShowModal(true);
        // Optionnel : réinitialiser les champs du formulaire
        setEmail("");
        setSubject("");
        setText("");
      } else {
        console.error("Erreur lors de l'envoi du message.");
      }
    } catch (error) {
      console.error("Erreur de requête:", error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container my-5">
        <div id="titre-page" className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <h1 className="display-4" data-aos="fade-down">
              Page de contact
            </h1>
            <hr className="my-4" />
            <h2 data-aos="fade-up">Une question, un projet ?</h2>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="formcontact">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Votre adresse email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              title="Veuillez entrer une adresse email valide."
            />
          </div>
          <div className="mb-3">
            <label htmlFor="subject" className="form-label">
              Sujet de votre message
            </label>
            <input
              type="text"
              className="form-control"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              maxLength={50}
              title="Le sujet ne doit pas dépasser 100 caractères."
            />
              <div id="limit" className="form-text text-muted">
              {subject.length}/50 caractères.
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="text" className="form-label">
              Votre message
            </label>
            <textarea
              className="form-control"
              id="text"
              rows={3}
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
              maxLength={1000}
              title="Le message ne doit pas dépasser 1000 caractères."
            ></textarea>
            <div id="limit" className="form-text text-muted">
              {text.length}/1000 caractères.
            </div>
          </div>

          <button type="submit" className="btn btn-contact">
            Envoyez votre message
          </button>
        </form>
      </div>

      {/* Modal de confirmation */}
      {showModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Message envoyé</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Merci de m&apos;avoir contacté. Je reviendrai vers vous
                  bientôt.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;

import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar'; // Assurez-vous d'importer correctement le composant Navbar
import '../styles/portfolio.css'; // Assurez-vous que le chemin vers votre CSS est correct

const apiUrl = process.env.REACT_APP_API_URL;

const Portfolio = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${apiUrl}/api.php?type=projects`);
        if (!response.ok) {
          throw new Error('Une erreur est survenue lors de la récupération des projets');
        }
        const data = await response.json();
        setProjects(data);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('Une erreur inconnue est survenue');
        }
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return <div>Chargement des projets...</div>;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <div>
      <Navbar />
      {/* Partie pour l'entête */}
      <div className="container my-5">
        <div id="titre-page" className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <h1 className="display-4" data-aos="fade-down">Portfolio</h1>
            <hr className="my-4" />
            <h2 data-aos="fade-up">Mes projets</h2>
          </div>
        </div>

        {/* Affichage des projets */}
        <div className="row">
          {projects.map((project) => (
            <div className="col-md-4" key={project.id}>
              <div id="card-project" className="card mb-4">
                <img
                  src={`${apiUrl}/uploads/${project.photo}`}
                  className="card-img-top"
                  alt={project.title}
                  width={500}
                  height={300}
                />
                <div className="card-body">
                  <h5 className="card-title">{project.title}</h5>
                  <p className="card-text">{project.description}</p>
                  <a href={project.lien} className="btn btn-project" target="_blank" rel="noopener noreferrer">
                    Voir le projet
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Utilisation de react-router-dom pour la navigation
import "../styles/connexion.css"

const apiUrl = process.env.REACT_APP_API_URL;

const Connexion1 = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Fonction de navigation

  const handleSendCode = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name }),
      });

      const data = await response.json();
      setMessage(data.message || "Code envoyé si l'utilisateur existe.");

      if (data.message === 'Code envoyé') {
        navigate('/connexion2'); // Redirection vers la page de connexion 2
      }
    } catch {
      setMessage('Erreur lors de l\'envoi du nom.');
    }
  };

  return (
    <div className="container">
      <h1 className="my-4">Connexion Administrateur</h1>
      <form onSubmit={handleSendCode}>
        <div className="form-group">
          <label>Nom :</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Envoyer le code</button>
      </form>
      {message && <p className="mt-3 response-message">{message}</p>}
    </div>
  );
};

export default Connexion1;

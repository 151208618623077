import { useState } from "react";
import { useNavigate } from 'react-router-dom'; 

export default function AddProject() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate(); 
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${apiUrl}/api.php`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();
      if (result.error) {
        setMessage(result.error);
      } else {
        setMessage("Projet ajouté avec succès !");
        form.reset();
      }
    } catch (error) {
      setMessage("Erreur lors de l'ajout du projet.");
      console.log("Error:", error);
    }
  };

  return (
    <div>
       <div className="btn-group" role="group" aria-label="Tableau de bord">
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/addproject')}
        >
          addproject
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/deleteproject')}
        >
          deleteproject
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/manageskills')}
        >
          manageskills
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Titre :</label>
          <input type="text" name="title" className="form-control" required />
        </div>
        <div className="form-group">
          <label>Photo :</label>
          <input type="file" name="photo" className="form-control" required />
        </div>
        <div className="form-group">
          <label>Description :</label>
          <input
            type="text"
            name="description"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Lien :</label>
          <input type="text" name="lien" className="form-control" required />
        </div>
        <button type="submit" className="btn btn-primary">
          Ajouter le Projet
        </button>
      </form>
      {message && <p className="mt-3">{message}</p>}
    </div>
  );
}

import React from 'react';

export default function AgeCalculator({ dateDeReference }) {
    const calculerAge = () => {
        const dateToCalculate = new Date(dateDeReference);
        const today = new Date();
        let age = today.getFullYear() - dateToCalculate.getFullYear();
        const monthDifference = today.getMonth() - dateToCalculate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dateToCalculate.getDate())) {
            age--;
        }
        return age;
    };

    return (
        <span>{calculerAge()}</span>
    );
}

import React from 'react';
import Navbar from '../components/Navbar'; // Assurez-vous que le chemin d'importation est correct
import '../styles/expro.css'; // Assurez-vous que le chemin d'importation du CSS est correct

const Experience = () => {
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <div id="titre-page" className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <h1 className="display-4" data-aos="fade-down">À propos de moi</h1>
            <hr className="my-4" />
            <h2 data-aos="fade-up">Mes expériences professionnelles</h2>
          </div>
        </div>

        <div className="presentation-expro">
          <p>Après plusieurs années passées dans le secteur industriel, où j&apos;ai évolué dans divers domaines tels que la construction et l&apos;usinage, j&apos;ai décidé de me réorienter vers le développement web. Fort de cette expérience, j&apos;apporte une approche structurée et méthodique à mon travail en tant que développeur. Mes précédents rôles m&apos;ont permis de développer un sens aigu du détail et de la rigueur, des compétences essentielles que j&apos;intègre désormais dans la réalisation de mes projets web. Mon parcours professionnel est marqué par des expériences enrichissantes qui ont contribué à renforcer mon adaptabilité et ma capacité à relever de nouveaux défis techniques.</p>
        </div>

        <div id="experience">
          <div className="timeline">
            <h4>Agent de production</h4>
            <h6>2021</h6>
            <p>
              Durant l&apos;année 2021, j&apos;ai occupé l&apos;emploi d&apos;agent de fabrication dans le domaine alimentaire chez Vandemoortele durant le mois de mai, où j&apos;ai façonné à la chaîne des torsades à la crème pâtissière, carrés pâtissiers aux abricots et autres gourmandises sucrées. J&apos;ai ensuite exercé chez A.S. Pool en tant qu&apos;agent de fabrication dans l&apos;usine de Spay où j&apos;ai effectué l&apos;assemblage de bancs de piscine, dispositif de sécurité visant à dissimuler les couvertures d&apos;une piscine.
            </p>
          </div>

          <div className="timeline">
            <h4>Opérateur régleur en usinage</h4>
            <h6>2016-2019</h6>
            <p>Après avoir obtenu mon diplôme de technicien d&apos;usinage, j&apos;ai exercé le métier d&apos;opérateur régleur dans diverses entreprises :</p>
            <ul>
              <li>
                <strong>2019</strong> CKB TE Connectivity à Allonnes
              </li>
              <li>
                <strong>2017-2019</strong> Souriau Esterline à Champagné
              </li>
              <li>
                <strong>2017</strong> Cronite Mancelle
              </li>
              <li>
                <strong>2016</strong> Leroy Somer (stagiaire)
              </li>
            </ul>
          </div>

          <div className="timeline">
            <h4>Agent de finition</h4>
            <h6>2014</h6>
            <p>
              <strong> Fonderie d&apos;art MACHERET :</strong> Dans cette entreprise, mon rôle était de démouler les futurs trophées des lions d&apos;or de Cannes. Je devais m&apos;assurer de l&apos;ébavurage ainsi que du polissage. C&apos;est aussi ici que j&apos;ai découvert pour la première fois l&apos;usinage.
            </p>
          </div>

          <div className="timeline">
            <h4>Contrôleur qualité</h4>
            <h6>2011-2014</h6>
            <p>
              <strong> ALTIA Structure :</strong> J&apos;effectuais le contrôle visuel de cabines de tracteur, en vérifiant la peinture, la visserie et l&apos;étanchéité à l&apos;aide de ma check-list.
            </p>
          </div>

          <div className="timeline">
            <h4>Opérateur sur ligne de production</h4>
            <h6>2008-2010</h6>
            <p>
              <strong> DEMARRAIS Structure :</strong> Dans cette entreprise, je travaillais sur une ligne d&apos;habillage de cabine de tracteur. Mes tâches consistaient à mastiquer pour étanchéifier, assembler les custodes ainsi que les hayons, puis procéder à leur montage.
            </p>
          </div>

        </div>
      </div>
    </>
  );
};

export default Experience;

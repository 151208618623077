import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Skills from "./pages/Skills";
import ExPro from "./pages/ExPro";
import Contact from "./pages/Contact";
import Connexion1 from "./pages/Connexion1";
import Connexion2 from "./pages/Connexion2";
import Dashboard from "./pages/Dashboard";
import AddProject from "./pages/AddProject";
import DeleteProject from "./pages/DeleteProject";
import ManageSkills from "./pages/ManageSkills";

import Footer from "./components/Footer";
import VantaBackground from "./components/VantaBackground";
import AOSWrapper from "./components/AosWrapper";
import DashboardLayout from "./components/DashboardLayout";

function App() {
  return (
    <div className="app">
      <VantaBackground />
      <div className="bg" id="vanta">
        <AOSWrapper>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/portfolio" element={<Portfolio />}></Route>
            <Route path="/skills" element={<Skills />}></Route>
            <Route path="/expro" element={<ExPro />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/connexion1" element={<Connexion1 />}></Route>
            <Route path="/connexion2" element={<Connexion2 />}></Route>
            <Route
              path="/dashboard"
              element={
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              }
            ></Route>
            <Route
              path="/dashboard/addproject"
              element={
                <DashboardLayout>
                  <AddProject />
                </DashboardLayout>
              }
            ></Route>
            <Route
              path="/dashboard/deleteproject"
              element={
                <DashboardLayout>
                  <DeleteProject />
                </DashboardLayout>
              }
            ></Route>
            <Route
              path="/dashboard/manageskills"
              element={
                <DashboardLayout>
                  <ManageSkills />
                </DashboardLayout>
              }
            ></Route>
          </Routes>
        </AOSWrapper>
      </div>
      <Footer />
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const DashboardLayout = ({ children }) => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('Token manquant. Redirection vers la page de connexion...');
        setTimeout(() => navigate('/connexion1'), 3000);
        return;
      }

      try {
        // Création d'un formulaire pour inclure le token dans le corps de la requête
        const formData = new FormData();
        formData.append('action', 'verifyToken');
        formData.append('token', token); // Envoi du token via un champ hidden

        const verifyResponse = await fetch(`${apiUrl}/api.php`, {
          method: 'POST',
          body: formData,
        });

        const verifyData = await verifyResponse.json();
        if (verifyData.valid) {
          setIsTokenValid(true);
        } else {
          setMessage('Token invalide ou expiré');
          localStorage.removeItem('token');
          setTimeout(() => navigate('/connexion1'), 3000);
        }
      } catch {
        setMessage('Erreur lors de la vérification du token.');
        localStorage.removeItem('token');
        setTimeout(() => navigate('/connexion1'), 3000);
      }
    };

    verifyToken();
  }, [navigate]);

  if (!isTokenValid) {
    return (
      <div className="container">
        <h1>Erreur de connexion</h1>
        <p>{message}</p>
      </div>
    );
  }

  return <>{children}</>;
};

export default DashboardLayout;

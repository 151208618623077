import React, { useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import '../styles/home.css';
import Typed from 'typed.js';
import AgeCalculator from '../components/AgeCalculator';


import portrait from "../media/portrait.png";
import linkedin from "../media/linkedin-logo.png";

export default function Home() {
  const typedElement = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedElement.current, {
      strings: [
        "autodidacte",
        "persévérant",
        "ordonné",
        "conciliant",
        "créatif",
        "autonome",
        "enthousiaste",
        "logique"
      ],
      typeSpeed: 50,
      backSpeed: 5,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);


  return (
    <div className='home'>
        <Navbar />
        
          <div className="container my-5">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 text-center">
                <h1 className="display-4" data-aos="fade-down">Legarlantezeck Benoît</h1>
                <hr className="my-4" />
                <h2 data-aos="fade-up">Développeur web</h2>
              </div>
            </div>
          </div>

          <div className="container my-5">
            <div className="row justify-content-center align-items-stretch">
              <div className="col-12 col-md-4 text-center d-flex align-items-center">
                <img
                  src={portrait}
                  alt="ia-Benoît"
                  className="img-fluid mb-4 portrait"
                  style={{ maxWidth: '65%', height: 'auto' }}
                />
              </div>

              <div className="col-12 col-md-4 text-center d-flex align-items-center">
                <p className="lead">
                  Bonjour et bienvenue sur mon portfolio ! Je m'appelle Benoît, j'ai choisi de me spécialiser dans le développement web pour allier créativité et
                  rigueur technique. Sur ce site, vous découvrirez mes compétences, mes projets et
                  mon parcours. N'hésitez pas à explorer et à en apprendre davantage sur mon travail.<br />
                  <span>Je suis un développeur<br /> <span ref={typedElement} style={{ color: 'gold', fontSize: 'xx-large' }} /></span>
                </p>
              </div>

              <div className="col-12 col-md-4 text-center d-flex align-items-center">
                <div className="infos">
                  <p><strong>Âge:</strong> <AgeCalculator dateDeReference="1984-04-22" /> ans</p>
                  <p><strong>Ville:</strong> Le Mans</p>
                  <p>
                    <a href="https://www.linkedin.com/in/benoit-lgrk/" target="_blank" rel="noopener noreferrer">
                      <img src={linkedin} alt="logo LinkedIn" width={47} height={47} />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-12 text-center">
                <a
                  href="/CV_Benoît_Legarlantezeck.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-home"
                >
                  Mon CV en PDF
                </a>
              </div>
            </div>
          </div>
        </div>
  );
}

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three'; 
import CELLS from 'vanta/dist/vanta.cells.min'; 

const VantaBackground = () => {
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaRef.current) return; 

    // Initialisation de l'effet Vanta.js avec la configuration personnalisée
    const vantaEffect = CELLS({
      el: vantaRef.current,
      THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 0.7,
      color1: 0x211739,
      color2: 0x141987,
      size: 1.3,
      speed: 1.7,
    });

    // Nettoyage de l'effet à la destruction du composant
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []); // Le tableau vide [] signifie que cet effet se déclenche une seule fois lors du montage

  return (
    <div 
      ref={vantaRef} 
      style={{ width: '100%', height: '100vh', position: 'absolute', top: 0, left: 0, zIndex: -1 }} 
    />
  );
};

export default VantaBackground;

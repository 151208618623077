import React from 'react';
import { useNavigate } from 'react-router-dom'; 

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <h1>Tableau de bord</h1>
      <div className="btn-group" role="group" aria-label="Tableau de bord">
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/addproject')}
        >
          addproject
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/deleteproject')}
        >
          deleteproject
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/manageskills')}
        >
          manageskills
        </button>
      </div>
    </div>
  );
};

export default Dashboard;

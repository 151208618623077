import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="text-center mt-5 fixed-bottom">
            <p>
                <Link to="/connexion1" style={{ color: 'inherit', textDecoration: 'none' }}>&copy;</Link>
                2024 Tous droits réservés.Legarlantezeck Benoît.
            </p>
        </footer>
    );
};

export default Footer;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const Connexion2 = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api.php`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, code, password }),
      });

      const data = await response.json();
      setMessage(data.message || 'Erreur lors de la vérification.');

      if (data.token) {
        localStorage.setItem('token', data.token); 
        navigate('/dashboard'); 
      }
    } catch {
      setMessage("Erreur lors de l'envoi du code.");
    }
  };

  return (
    <div className="container">
      <h1 className="my-4">Vérification du Code</h1>
      <form onSubmit={handleVerifyCode}>
        <div className="form-group">
          <label>Email :</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Code :</label>
          <input
            type="text"
            className="form-control"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Mot de passe :</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Vérifier le Code</button>
      </form>
      {message && <p className="mt-3 response-message">{message}</p>}
    </div>
  );
};

export default Connexion2;

import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar'; // Assurez-vous que le chemin d'importation est correct
import '../styles/skills.css'; // Assurez-vous que le chemin d'importation du CSS est correct

const apiUrl = process.env.REACT_APP_API_URL;

const Competence = () => {
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await fetch(`${apiUrl}/api.php?type=languages`);
        if (!response.ok) {
          throw new Error('Une erreur est survenue lors de la récupération des compétences');
        }
        const data = await response.json();
        setSkills(data);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('Une erreur inconnue est survenue');
        }
        setLoading(false);
      }
    };

    fetchSkills();
  }, []);

  const updateProgressBar = (id, percentage) => {
    const element = document.getElementById(id);
    if (!element) return;
    element.style.width = `${percentage}%`;
    element.setAttribute('aria-valuenow', percentage.toString());
    element.innerText = `${percentage}%`;
  };

  useEffect(() => {
    skills.forEach((skill) => {
      updateProgressBar(`progress-${skill.id}`, skill.proficiency);
    });
  }, [skills]);

  if (loading) {
    return <div>Chargement des compétences...</div>;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  // Séparer les compétences en languages et frameworks
  const languages = skills.filter(skill => skill.type === 'language');
  const frameworks = skills.filter(skill => skill.type === 'framework');

  return (
    <>
      <Navbar />
      <div className="container my-5">
        <div id="titre-page" className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <h1 className="display-4" data-aos="fade-down">Mes compétences</h1>
            <hr className="my-4" />
            <h2 data-aos="fade-up">Languages et frameworks</h2>
          </div>
        </div>
        <div className="row">
          {/* Affichage des Languages */}
          <div className="col-12 col-md-6">
            <h3>Languages</h3>
            {languages.map((language) => (
              <div className="d-flex align-items-center mb-3" key={language.id}>
                <img
                  src={`${apiUrl}/uploads/${language.logo_url}`}
                  alt={language.name}
                  className="logo"
                  width={50} 
                  height={50} 
                />
                <div className="flex-grow-1 ml-3">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      id={`progress-${language.id}`}
                      role="progressbar"
                      style={{ width: '0%' }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Affichage des Frameworks */}
          <div className="col-12 col-md-6">
            <h3>Frameworks</h3>
            {frameworks.map((framework) => (
              <div className="d-flex align-items-center mb-3" key={framework.id}>
                <img
                  src={`${apiUrl}/uploads/${framework.logo_url}`}
                  alt={framework.name}
                  className="logo"
                  width={50} 
                  height={50} 
                />
                <div className="flex-grow-1 ml-3">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      id={`progress-${framework.id}`}
                      role="progressbar"
                      style={{ width: '0%' }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Competence;

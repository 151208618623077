import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
const apiUrl = process.env.REACT_APP_API_URL;

export default function ManageSkills() {
  const navigate = useNavigate();
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newSkill, setNewSkill] = useState({
    name: '',
    logoFile: null,
    proficiency: 0,
    type: 'language'
  });

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await fetch(`${apiUrl}/api.php?type=languages`);
        if (!response.ok) {
          throw new Error('Une erreur est survenue lors de la récupération des compétences');
        }
        const data = await response.json();
        setSkills(data);
      } catch (error) {
        setError(error.message || 'Une erreur inconnue est survenue');
      } finally {
        setLoading(false);
      }
    };

    fetchSkills();
  }, []);

  const handleProficiencyChange = async (id, proficiency) => {
    try {
      const response = await fetch(`${apiUrl}/api.php`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id, proficiency })
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de la maîtrise');
      }

      const updatedSkills = skills.map(skill => 
        skill.id === id ? { ...skill, proficiency } : skill
      );

      setSkills(updatedSkills);
    } catch (error) {
      setError(error.message || 'Une erreur inconnue est survenue');
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/api.php?id=${id}&type=languages`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Une erreur est survenue lors de la suppression de la compétence');
      }

      setSkills(skills.filter(skill => skill.id !== id));
    } catch (error) {
      setError(error.message || 'Une erreur inconnue est survenue');
    }
  };

  const handleAddSkill = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', newSkill.name);
      formData.append('logo', newSkill.logoFile);
      formData.append('proficiency', newSkill.proficiency.toString());
      formData.append('type', newSkill.type);

      const response = await fetch(`${apiUrl}/api.php`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error("Erreur lors de l'ajout de la compétence");
      }

      const addedSkill = await response.json();
      setSkills([...skills, addedSkill]);
      setNewSkill({ name: '', logoFile: null, proficiency: 0, type: 'language' });
    } catch (error) {
      setError(error.message || 'Une erreur inconnue est survenue');
    }
  };

  if (loading) {
    return <div>Chargement des compétences...</div>;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  const languages = skills.filter(skill => skill.type === 'language');
  const frameworks = skills.filter(skill => skill.type === 'framework');

  return (
    <div>
       <div className="btn-group" role="group" aria-label="Tableau de bord">
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/addproject')}
        >
          addproject
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/deleteproject')}
        >
          deleteproject
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/manageskills')}
        >
          manageskills
        </button>
      </div>
      <div className="container my-5">
        <h1 className="display-4">Gestion des compétences</h1>

        {/* Formulaire pour ajouter une nouvelle compétence */}
        <form onSubmit={handleAddSkill} className="my-4">
          <div className="form-group">
            <label>Nom de la compétence</label>
            <input
              type="text"
              className="form-control"
              value={newSkill.name}
              onChange={(e) => setNewSkill({ ...newSkill, name: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Logo de la compétence</label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => setNewSkill({ ...newSkill, logoFile: e.target.files ? e.target.files[0] : null })}
              required
            />
          </div>
          <div className="form-group">
            <label>Maîtrise</label>
            <input
              type="range"
              className="custom-range"
              min="0"
              max="100"
              value={newSkill.proficiency}
              onChange={(e) => setNewSkill({ ...newSkill, proficiency: parseInt(e.target.value) })}
              required
            />
            <span>{newSkill.proficiency}%</span>
          </div>
          <div className="form-group">
            <label>Type</label>
            <div>
              <label>
                <input
                  type="radio"
                  name="type"
                  value="language"
                  checked={newSkill.type === 'language'}
                  onChange={() => setNewSkill({ ...newSkill, type: 'language' })}
                /> Langage
              </label>
              <label className="ml-3">
                <input
                  type="radio"
                  name="type"
                  value="framework"
                  checked={newSkill.type === 'framework'}
                  onChange={() => setNewSkill({ ...newSkill, type: 'framework' })}
                /> Framework
              </label>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Ajouter la compétence</button>
        </form>

        <div className="row">
          <div className="col-12 col-md-6">
            <h3>Languages</h3>
            {languages.map((skill) => (
              <div className="d-flex align-items-center mb-3" key={skill.id}>
                <img
                  src={`${apiUrl}/uploads/${skill.logo_url}`}
                  alt={skill.name}
                  className="logo"
                  width={50}
                  height={50}
                />
                <div className="flex-grow-1 ml-3">
                  <h5>{skill.name}</h5>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${skill.proficiency}%` }}
                      aria-valuenow={skill.proficiency}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  <input
                    type="range"
                    className="custom-range mt-2"
                    min="0"
                    max="100"
                    value={skill.proficiency}
                    onChange={(e) => handleProficiencyChange(skill.id, parseInt(e.target.value))}
                  />
                </div>
                <button
                  className="btn btn-danger ml-3"
                  onClick={() => handleDelete(skill.id)}
                >
                  Supprimer
                </button>
              </div>
            ))}
          </div>
          <div className="col-12 col-md-6">
            <h3>Frameworks</h3>
            {frameworks.map((skill) => (
              <div className="d-flex align-items-center mb-3" key={skill.id}>
                <img
                  src={`${apiUrl}/uploads/${skill.logo_url}`}
                  alt={skill.name}
                  className="logo"
                  width={50}
                  height={50}
                />
                <div className="flex-grow-1 ml-3">
                  <h5>{skill.name}</h5>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${skill.proficiency}%` }}
                      aria-valuenow={skill.proficiency}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  <input
                    type="range"
                    className="custom-range mt-2"
                    min="0"
                    max="100"
                    value={skill.proficiency}
                    onChange={(e) => handleProficiencyChange(skill.id, parseInt(e.target.value))}
                  />
                </div>
                <button
                  className="btn btn-danger ml-3"
                  onClick={() => handleDelete(skill.id)}
                >
                  Supprimer
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from"../media/logo.png";

const Navbar = () => {
  const location = useLocation(); // Hook to get the current path
  const pathname = location.pathname;

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="logo" width={50} height={50} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className={`nav-link ${pathname === '/' ? 'active' : ''}`}>Accueil</Link>
            </li>
            <li className="nav-item">
              <Link to="/portfolio" className={`nav-link ${pathname === '/portfolio' ? 'active' : ''}`}>Mon portfolio</Link>
            </li>
            <li className="nav-item">
              <Link to="/skills" className={`nav-link ${pathname === '/skills' ? 'active' : ''}`}>Mes compétences</Link>
            </li>
            <li className="nav-item">
              <Link to="/expro" className={`nav-link ${pathname === '/expro' ? 'active' : ''}`}>A propos de moi</Link>
            </li>
          </ul>

          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/contact" className={`nav-link text-danger contact-link ${pathname === '/contact' ? 'active' : ''}`}>Contactez moi</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

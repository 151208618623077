import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
const apiUrl = process.env.REACT_APP_API_URL;

export default function DeleteProject() {
  const navigate = useNavigate(); 
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${apiUrl}/api.php?type=projects`);
        if (!response.ok) {
          throw new Error('Une erreur est survenue lors de la récupération des projets');
        }
        const data = await response.json();
        setProjects(data);
        setLoading(false);
      } catch (error) {
        setError(error.message || 'Erreur inconnue');
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);


  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/api.php?id=${id}&type=projects`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Une erreur est survenue lors de la suppression du projet');
      }
      setProjects(projects.filter((project) => project.id !== id));
    } catch (error) {
      setError(error.message || 'Erreur inconnue');
    }
  };

  if (loading) {
    return <div>Chargement des projets...</div>;
  }

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <div>
       <div className="btn-group" role="group" aria-label="Tableau de bord">
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/addproject')}
        >
          addproject
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/deleteproject')}
        >
          deleteproject
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/dashboard/manageskills')}
        >
          manageskills
        </button>
      </div>
      <div className="row">
        {projects.map((project) => (
          <div className="col-md-4" key={project.id}>
            <div className="card mb-4">
              <img
                src={`${apiUrl}/uploads/${project.photo}`}
                className="card-img-top"
                alt={project.title}
                style={{ width: '100%', height: 'auto' }}
              />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <p className="card-text">{project.description}</p>
                <button onClick={() => handleDelete(project.id)} className="btn btn-danger">Supprimer le projet</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
